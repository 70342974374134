import React from "react";
import "./AboutUs.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

function AboutUs() {
  return (
    <>
      <Header />
      <div className="about-main-container">
        <div className="about-header"
          style={{
            backgroundImage: `url(${require('../../Assets/Images/office.jpg')})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            minHeight: '5vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <span className="about-header-title">About Us</span>
        </div>
        <div className="about-sub-container">
          <div className="about-sub-level-first">
            <div className="about-sub-level-first-left">
              <img src={require('../../Assets/Images/mobile.png')} alt="mobile-img" className="about-mob-img" />
            </div>
            <div className="about-sub-level-first-right">
              <div className="about-sub-level-first-right-div">
                <span className="about-sub-level-first-right-title">Revolutionary software solutions for your business</span>
              </div>
              <div className="about-sub-level-second-right-div">
                <span className="about-sub-level-first-right-title-content">As a software partner, we are committed to providing practical and customized solutions to meet your business needs</span>
              </div>
            </div>
          </div>
          <div>
            <div className="about-sub-level-second">
              <span className="about-sub-level-second-title">Flexible pricing options to cater to </span>
            </div>
            <div className="about-sub-level-second">
              <span className="about-sub-level-second-title">businesses of all sizes</span>
            </div>
          </div>
          <div className="about-sub-level-third">
            <div>
              <img src={require('../../Assets/Images/passport.jpg')} alt="ceo-img" className="ceo-img-class" />
            </div>
            <div className="ceo-message-grp">
              <div>
                <span className="ceo-message-content-title">
                  Rabendra Sharma, CEO & Founder of Just Mind Work Pvt Ltd.
                </span>
              </div>
              <div className="ceo-message-sub-content-grp">
                <span className="ceo-message-sub-content">
                  As the founder and chief visionary, Rabendra Sharma is committed to driving the company's growth by leading the development of cutting-edge software, marketing strategies, and customers. 
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AboutUs;
