import React from "react";
import "./Header.css"
function HeaderLevelFirst(){
   return(
    <>
      <div className="header-level-first-main">
        <span className="header-level-first-title">Free for initial  16 Day's</span>
      </div>
    </>
   );
}

export default HeaderLevelFirst;
