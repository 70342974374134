import React from "react";
import "./PrivacyPolicy.css"
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
function PrivacyPolicy(){
  return(
    <>
      <Header />
      <div className="privacy-policy-main-container">
        <div className="privacy-policy-main-sub-container">
          <div className="privacy-policy-main-heading">
            <span className="privacy-policy-main-heading-title">Billeasy Privacy Policy</span>
          </div>

          <div className="privacy-policy-intro">
            <span className="privacy-intro-title">
              Introduction
            </span>
            <span className="privacy-intro-sub-title">
              At Billeasy, we are committed to protecting your privacy. This privacy policy outlines the practices of Simply Billeasy, 
              the company behind the Billeasy app.
            </span>
          </div>

          <div className="privacy-policy-intro">
            <span className="privacy-intro-title">
              App and Developer Information
            </span>
            <span className="privacy-intro-sub-title">
            App Name: Billing App GST Invoice Maker (also known as "Billeasy")
            - Developer Name: Invoicing, Billing, Inventory, GST, Accounting App
            </span>
          </div>

          <div className="privacy-policy-intro">
            <span className="privacy-intro-title">
            General Terms
            </span>
            <span className="privacy-intro-sub-title">
            By using Billeasy, you agree to the terms outlined in this privacy policy. If you do not agree, you may not use our services. This agreement is governed by the laws of India.
            </span>
          </div>

          <div className="privacy-policy-intro">
            <span className="privacy-intro-title">
            Modifications
            </span>
            <span className="privacy-intro-sub-title">
            We may update these terms periodically. Continued use of our services indicates your acceptance of any changes. Please review our terms regularly.
            </span>
          </div>

          <div className="privacy-policy-intro">
            <span className="privacy-intro-title">
              Scope of Privacy Statement
            </span>
            <span className="privacy-intro-sub-title">
              This statement applies to users who have downloaded the Billeasy application and agreed to the terms and conditions by clicking the “I Agree/Login” button.
            </span>
          </div>

          <div className="privacy-policy-intro">
            <span className="privacy-intro-title">
              Description of Services
            </span>
            <span className="privacy-intro-sub-title">
              Billeasy provides services such as inventory management, invoicing, reporting tools, and estimate/order form creation. Users can download the application from the official website or Google Play Store. An internet connection is required for downloading but not for usage. Users are responsible for ensuring compliance with GST and other applicable laws.
            </span>
          </div>

          <div className="privacy-policy-intro">
            <span className="privacy-intro-title">
              Business Data and Privacy
            </span>
            <span className="privacy-intro-sub-title">
              Users are responsible for maintaining the confidentiality of their username, password, and other sensitive information. In case of unauthorized use, contact us immediately at help.billeasy@justmindwork.com. We are not liable for any loss or damage resulting from unauthorized access to user accounts.
            </span>
          </div>

          <div className="privacy-policy-intro">
            <span className="privacy-intro-title">
              Data Ownership
            </span>
            <span className="privacy-intro-sub-title">
              Users own the content they create or store. Billeasy respects this ownership but retains the right to use, reproduce, adapt, modify, publish, or distribute the content for internal purposes. While we strive to keep user data safe, we are not responsible for any data leaks.
            </span>
          </div>

          <div className="privacy-policy-intro">
            <span className="privacy-intro-title">
              Permissions
            </span>
            <span className="privacy-intro-sub-title">
              Billeasy requires the following permissions for optimal functionality:
            </span>
            <ul>
              <li className="privacy-policy-list-item">Camera: To capture images of bills.</li>
              <li className="privacy-policy-list-item"> Contacts: To sync contacts and identify potential buyers and suppliers.</li>
              <li className="privacy-policy-list-item">Storage: To store KYC and other relevant documents.</li>
              <li className="privacy-policy-list-item">Location: To verify user location and prevent fraud.</li>
              <li className="privacy-policy-list-item">SMS: To understand income, spending patterns, and fetch expenses.</li>
              <li className="privacy-policy-list-item">Device Info: To uniquely identify devices and prevent fraud.</li>
              <li className="privacy-policy-list-item">Accounts: To monitor account information for credit profile enrichment.</li>
              <li className="privacy-policy-list-item">Apps: To assess creditworthiness based on installed applications.</li>
              <li className="privacy-policy-list-item">Call Logs: To validate phone numbers and assess profiles for credit limits.</li>
              <li className="privacy-policy-list-item">Camera: To capture images of bills.</li>
              <li className="privacy-policy-list-item">Calendar: To collect event data for credit profile enrichment.</li>
            </ul>
          </div>

          <div className="privacy-policy-intro">
            <span className="privacy-intro-title">
              Data Ownership
            </span>
            <span className="privacy-intro-sub-title">
              Users own the content they create or store. Billeasy respects this ownership but retains the right to use, reproduce, adapt, modify, publish, or distribute the content for internal purposes. While we strive to keep user data safe, we are not responsible for any data leaks.
            </span>
          </div>

          <div className="privacy-policy-intro">
            <span className="privacy-intro-title">
              Permissions Usage
            </span>

            <ul className="privacy-policy-list">
              <li className="privacy-policy-list-item">Contacts: Sync contacts for seamless mobile-to-desktop integration and identify existing Billeasy users.</li>
              <li className="privacy-policy-list-item">SMS: Collect and monitor SMS data to understand financial patterns and enable financial services. Personal or OTP messages are not read.</li>
            </ul>
          </div>

          <div className="privacy-policy-intro">
            <span className="privacy-intro-title">
              Data Security
            </span>
            <span className="privacy-intro-sub-title">
              Billeasy employs industry-standard security measures to protect your data. This includes physical, technical, and administrative safeguards, continuous monitoring, and regular security updates. Users are responsible for keeping their account credentials confidential.
            </span>
          </div>

          <div className="privacy-policy-intro">
            <span className="privacy-intro-title">
              Data Retention and Deletion
            </span>
            <span className="privacy-intro-sub-title">
              Personal information is retained as necessary. Upon account deactivation, personal data is deleted unless required for legal compliance. Users can request data deletion by contacting us.
            </span>
          </div>

          <div className="privacy-policy-intro">
            <span className="privacy-intro-title">
              Third-Party SDK
            </span>
            <span className="privacy-intro-sub-title">
              Billeasy uses a third-party SDK for credit risk assessment. We ensure that our third-party providers implement stringent security measures to protect your data.
            </span>
          </div>

          <div className="privacy-policy-intro">
            <span className="privacy-intro-title">
              Communications
            </span>
            <span className="privacy-intro-sub-title">
              Users agree to receive promotional, transactional, and commercial communications from Billeasy through various channels.
            </span>
          </div>

          <div className="privacy-policy-intro">
            <span className="privacy-intro-title">
              Refund, Cancellation, and Renewal Policies
            </span>
            <ol className="privacy-policy-list">
              <li className="privacy-policy-list-item">Eligibility for Full Refund: Submit a refund request within 7 days from the purchase date for a full refund.</li>
              <li className="privacy-policy-list-item">Requesting Refunds: Submit refund requests via email or our website.</li>
              <li className="privacy-policy-list-item">Other Refund Policies: Refunds apply only to purchases of one year or longer.</li>
              <li className="privacy-policy-list-item">Non-Refundable Fees: Convenience fees are non-refundable.</li>
              <li className="privacy-policy-list-item">Manual Renewal: Licenses must be manually renewed at the end of their validity.</li>
            </ol>
          </div>

          <div className="privacy-policy-intro">
            <span className="privacy-intro-title">
              Contact Information
            </span>
            <span className="privacy-intro-sub-title">
              For any questions or concerns regarding this agreement, please contact us at help billeasy@justmindwork.com.
            </span>
          </div>

          <div className="privacy-policy-intro">
            <span className="privacy-intro-title">
              Ends of Terms of Service
            </span>
            <span className="privacy-intro-sub-title">
              By using Billeasy, you acknowledge that you have read, understood, and agreed to these terms.
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
