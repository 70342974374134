import React from "react";
import "./Header.css"
import HeaderLevelFirst from "./HeaderLevelFirst";
// import HeaderLevelSecond from "./HeaderLevelSecond";
import HeaderLevelThird from "./HeaderLevelThird";
	function Header(){
		return(
			<>
				<HeaderLevelFirst />
				{/* <HeaderLevelSecond /> */}
				{/* <hr /> */}
				<HeaderLevelThird />
				{/* <hr /> */}
			</>
		);
	}

export default Header;
