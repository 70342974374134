import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css';

function HeaderLevelThird() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">
          <img
            src={require('../../Assets/Images/logo.png')}
            alt="Logo"
            className="d-inline-block align-top"
            style={{ width: '120px', height: 'auto' }}
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleMenu}
          aria-controls="navbarNav"
          aria-expanded={menuOpen ? "true" : "false"}
          aria-label="Toggle navigation"
        >
          {menuOpen ? <FaTimes /> : <FaBars />}
        </button>
        <div className={`collapse navbar-collapse ${menuOpen ? 'show full-screen-menu' : ''}`} id="navbarNav">
          {menuOpen && (
            <FaTimes className="close-button" onClick={toggleMenu} />
          )}
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/" className="nav-link header-attribute-name" onClick={menuOpen ? toggleMenu : null}>Home</Link>
            </li>
            <li className="nav-item">
              <Link
                to="/about-us"
                className="nav-link header-attribute-name"
                onClick={menuOpen ? toggleMenu : null}
              >
                Powered by JMW
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/inventory" className="nav-link header-attribute-name" onClick={menuOpen ? toggleMenu : null}>Inventory</Link>
            </li>
            <li className="nav-item">
              <Link to="/sale" className="nav-link header-attribute-name" onClick={menuOpen ? toggleMenu : null}>Sales</Link>
            </li>
            <li className="nav-item">
              <Link to="/purchase" className="nav-link header-attribute-name" onClick={menuOpen ? toggleMenu : null}>Purchase</Link>
            </li>
            <li className="nav-item">
              <Link to="/tax" className="nav-link header-attribute-name" onClick={menuOpen ? toggleMenu : null}>Taxes</Link>
            </li>
            <li className="nav-item">
              <Link
                to="/contact-us"
                className="nav-link header-attribute-name"
                onClick={menuOpen ? toggleMenu : null}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default HeaderLevelThird;
