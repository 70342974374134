import React, { useState } from "react";
import axios from "axios";
import "./ContactUs.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import SuccessNotification from "../Notification/SuccessNotification";
import { NotificationContainer } from "react-notifications";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    question: ""
  });

  const [responseMessage, setResponseMessage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post("https://justmindwork.com/api/contacts", {
        name: formData.name,
        contact: {
          email: formData.email,
          phone_number: formData.phone,
          description: formData.question,
        }
      });

      if (response.status === 201) {
        SuccessNotification("Your message has been sent successfully!");
        setFormData({
          name: "",       
          phone: "",      
          email: "",      
          question: ""    
        });
        
      } else {
        setResponseMessage("There was an error sending your message. Please try again.");
      }
    } catch (error) {
      setResponseMessage("There was a problem sending your message. Please try again later.");
    }
  };

  return (
    <>
      <Header />
      <div className="contact-container">
        <div className="contact-header">
          <h1>Contact us</h1>
        </div>
        <div className="contact-form-container">
          <div>
            <span className="contact-subtitle">
              Contact us about anything related to our company or services. We'll do our best to get back to you as soon as possible.
            </span>
            <form className="contact-form" onSubmit={handleSubmit}>
              <label htmlFor="name">Name *</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}       
                onChange={handleChange} 
                required
              />

              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}       
                onChange={handleChange}      
                placeholder="+91"
              />

              <label htmlFor="email">Email *</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}     
                onChange={handleChange}     
                required
              />

              <label htmlFor="question">Question *</label>
              <textarea
                id="question"
                name="question"
                value={formData.question}   
                onChange={handleChange}
                required
              ></textarea>

              <button type="submit">Submit</button>
            </form>
            {responseMessage && <p className="contact-message">{responseMessage}</p>}
          </div>
          <div className="contact-info">
            <h2>Just Mind Work Pvt Ltd</h2>
            <p>407, Satguru Parinay, Scheme 54, opposite C21 Mall, PU4, AB Road, Indore, Madhya Pradesh 452011</p>
            <p><i className="fas fa-phone-alt"></i> +91-9644780306</p>
            <p><i className="fas fa-envelope"></i> contact@justmindwork.com</p>
            <p><i className="fas fa-envelope"></i> business@justmindwork.com</p>
            <p><i className="fas fa-envelope"></i> sales@justmindwork.com</p>
          </div>
        </div>
      </div>
      <Footer />
      <NotificationContainer />
    </>
  );
}

export default ContactUs;
