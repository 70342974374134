import React, { useState } from "react";
import "./Home.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";

function Home() {
  const [isRotated, setIsRotated] = useState(false);
  const handleEnter = () => setIsRotated(true);
  const handleLeave = () => setIsRotated(false);
  const handleTouchStart = () => setIsRotated(true);
  const handleTouchEnd = () => setIsRotated(false);
  return (
    <>
      <Header />
      <div className="home-main-container">
        <div className="home-container-level-first">
          <div className="bubble bubble-1">
            <img src={require('../../Assets/Images/billlogo.png')} alt="bubble-1" />
          </div>
          <div className="bubble bubble-2">
            <img src={require('../../Assets/Images/billlogo.png')} alt="bubble-2" />
          </div>
          <div className="bubble bubble-3">
            <img src={require('../../Assets/Images/billlogo.png')} alt="bubble-3" />
          </div>
          <div className="bubble bubble-4">
            <img src={require('../../Assets/Images/billlogo.png')} alt="bubble-4" />
          </div>
          <div className="bubble bubble-5">
            <img src={require('../../Assets/Images/billlogo.png')} alt="bubble-5" />
          </div>
          <div className="animation-shape shape-triangle animation--rotating-diagonal">
            <div className="animation--rotating"></div>
          </div>
          <div className="home-container-level-first-grp">
            <span className="home-container-first-title">Unlock your business's full potential with Billeasy</span>
          </div>
          <div className="home-container-second-title">
            <span className="home-container-second-title-content">Connect with our support team to discuss your Business needs and get expert guidance to grow your business</span>
          </div>
          <div className="home-container-third-title">
            <span className="home-container-third-title-content">With "Billeasy" let your business on fire</span>
          </div>
          <div className="desktop-dashboard-image">
            <img src={require('../../Assets/Images/desktop-dashboard.png')} alt="desktop-img" className="home-desktop-img-class" />
          </div>
        </div>

        <div
          className="home-container-level-second"
          onMouseEnter={handleEnter}
          onMouseLeave={handleLeave}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          <div className="home-container-level-second-left">
            <img
              src={require('../../Assets/Images/mobile.png')}
              alt="mobile-img"
              className="home-mob-img-class"
              style={{ transform: isRotated ? 'rotate(360deg)' : 'rotate(0deg)' }}
            />
          </div>
          <div className="home-container-level-second-right">
            <div className="home-level-second-heading-title-grp">
              <span className="home-level-second-heading-title">
                Revolutionary software solutions for your business which can handle your day to day activities online & offline.
              </span>
            </div>
            <div className="home-level-second-title-name-grp">
              <span className="home-level-second-title-name">
                {/* As a software provider, we are committed to providing practical and customized solutions to meet your business needs */}
                Use our cutting-edge software to manage your activities with ease and transform your organization. 
                Utilize state-of-the-art solutions to streamline your operations and manage your daily activities with ease. 
                Ensure seamless and effective operations for your company by implementing a software solution that connects online and offline administration. 
                With our feature-rich software, which is customized to fulfill all of your demands, you can transform the way you do business.
              </span>
            </div>
          </div>
        </div>

        <div className="home-container-level-third">
          <div className="level-third-left">
            <span className="level-third-left-content">
              Billeasy can help you to manage your all Inventory,
              Sales, Purchases, & Taxes on your fingertips.
            </span>
          </div>
          <div className="level-third-right">
            <img src={require('../../Assets/Images/inventory.jpg')} alt="inventory-img" className="third-level-img-class" />
          </div>
        </div>

        <div className="home-container-level-fourth">
          <div className="level-fourth-left">
            <span className="level-fourth-left-content">
              We care about your Results
            </span>
            <div className="level-fourth-content-summary">
              <span className="level-fourth-left-sub-content">
                {/* At Billeasy, we put our clients' needs first by providing a feature-rich Report that makes financial data easier to understand.
                We make sure users can easily obtain accurate and comprehensive reports that support their decision-making. */}
                At Billeasy, we are committed to prioritizing our clients' needs by offering a feature-rich reporting system that transforms complex financial data into easily understandable insights. 
                Our robust reporting tools are designed with the user in mind, ensuring that accessing accurate and comprehensive reports is a seamless experience. Whether you're analyzing sales trends, monitoring expenses, 
                or evaluating overall financial health, our reports provide the clarity and depth necessary to support informed decision-making. We understand that in today's fast-paced business environment, 
                having reliable and easily accessible financial data is crucial, and that's why we go the extra mile to ensure our reporting features empower you to make the best choices for your business's future. With Billeasy,
                you can trust that every report is crafted to deliver the precision and detail you need, helping you stay ahead in your financial planning and strategy.
              </span>
            </div>
          </div>
          <div className="level-fourth-right slide-in">
            <img src={require('../../Assets/Images/report.jpg')} alt="report-img" className="fourth-level-img-class" />
          </div>
        </div>

        <div className="home-container-level-fifth">
          <div className="level-fifth-inner-container">
            <div className="level-fifth-inner-div">
              <div className="level-fifth-inner-header">
                <span className="fifth-inner-header-title">Easy Subscription</span>
              </div>
              <div className="fifth-inner-start-div">
                <span className="fifth-inner-start-span">Affordable subscription for all Business</span>
              </div>
              <div className="fifth-card-group-list">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">Complete Invoice Solutions</li>
                  <li className="list-group-item">
                    Get complete access to all our software modules
                  </li>
                  <li className="list-group-item">Unlimited customization</li>
                  <li className="list-group-last-item">24X7 Available Support</li>
                </ul>
              </div>
              <div className="fifth-card-footer">
                <p className="fifth-card-footer-paragraph">Instant setup, satisfied or reimbursed.</p>
                <Link to="/contact-us" className="fifth-card-button">
                  Start now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
